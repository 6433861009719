@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

.container {
    @media only screen and (max-width: 599px) {
        margin: 0;
        padding: 10px;
    }
}

.badge-pink {
    color: black;
    background-color: #FDA1B2;
}

.phi-modal {
    .mat-dialog-container {
        padding: 0 !important;

        .mat-dialog-content {
            margin: 0;
            padding: 0;
        }
    }
}

.phi-toast.toast {
    border: none;
    box-shadow: none;
    max-width: none;

    .toast-body {
        padding: unset;

        .alert {
            padding: 1.125rem 1rem 1.125rem 3rem;
        }
    }
}

.phi-button-bar {
    .btn {
        background-image: none;
    }
}

.phi-icon-input {
    input {
        border-right: 0;
    }

    .input-group-text {
        padding: 0;

        .icon-clickable {
            cursor: pointer;
        }
    }
}

.ck.ck-toolbar {
    background: none !important;
    border: none !important;
    padding: 0 3px 1px 0px !important;

    .ck-dropdown .ck-button {
        border: 1px solid #c4c4c4;
    }
}

.ck-editor__editable_inline {
    min-height: 150px !important;
}

.ck-editor__editable {
    max-height: 146px;
}

table.admin-table {
    .mat-header-cell {
        color: black;
        font-weight: 900;
        padding-top: 1rem;
        border-bottom: 2px solid #e0e2e2;

        h5 {
            font-weight: bold;
            color: black;
        }
    }

    width: 100%;

    .mat-row {
        cursor: pointer;

        &.alt-color {
            background-color: #F7F8F8;
        }
    }

    td.mat-cell {
        border: none;
        border-bottom-color: white;

        &.mat-column-receipt {
            text-align: center;
            justify-content: center;
        }
    }

    tr {
        &.detail-row {
            height: 0;
        }

        &.element-row:not(.expanded-row):hover {
            background: whitesmoke;
        }

        &.element-row:not(.expanded-row):active {
            background: #efefef;
        }
    }

    .detail {
        td {
            vertical-align: top;
        }
    }

    .element-row td {
        border-bottom: none;
    }

    .element-detail {
        overflow: hidden;
        display: flex;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &.sub-table {
        width: auto !important;
        margin-bottom: 12px;

        td, th {
            border: none !important;
            width: auto !important;
            padding-left: 0 !important;
        }
    }

    .dark-row {
        background-color: #F7F8F8;
    }
}

app-name-change-request-card,
app-donation-history-requests-card {
    .accordion .card {
        border: 1px solid #E0E2E2;
        box-shadow: 0 2px 5px 0 rgb(74 74 74 / 10%);

        .card-header {
            border-bottom: unset;
            padding: 0;
        }

        .card-header:hover {
            background-color: unset;
        }

        .card-body {
            background-color: unset;
            border-bottom: unset;
        }
    }
}

.mat-accordion .mat-expansion-panel.blue-header {
    border-radius: 0;

    .mat-expansion-indicator::after, .mat-expansion-panel-header-description {
        color: white;
    }

    &:first-of-type, &:last-of-type {
        border-radius: 0;
    }

    &:first-of-type {
        border-radius: 0;
    }

    .mat-expansion-panel-header {
        background: #006184;

        &:hover {
            background: #006184 !important;
        }

        &.mat-expanded:focus, .mat-expanded:hover {
            background: #006184;
        }

        .mat-expansion-panel-header-title {
            color: white;
        }
    }
}

a.btn.btn-primary {
    color: #fff;
}

// TODO AS: These are icons not found in the eden-icon library. These have been placed here for
// convenience until they're approved or replaced by the GVSG team.
.phi-icon {
    padding: 0 0.7rem;
    background-repeat: no-repeat;
    background-position: center center;

    &.phi-icon-sad {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='130' height='130' fill='%23000'><path id='svg_1' fill='%230D0F10' d='m97.2127,97.9057c2.2264,-1.4516 2.8543,-4.4333 1.4028,-6.6597c-7.1476,-10.9625 -19.532,-18.2257 -33.6155,-18.2257c-14.0835,0 -26.4679,7.2632 -33.6156,18.2257c-1.4516,2.2264 -0.8236,5.2081 1.4029,6.6597c2.2264,1.4517 5.2081,0.8236 6.6597,-1.4028c5.4464,-8.3531 14.8581,-13.8576 25.553,-13.8576c10.6948,0 20.1066,5.5045 25.553,13.8576c1.4516,2.2264 4.4333,2.8545 6.6597,1.4028z' clip-rule='evenodd' fill-rule='evenodd'/> <path id='svg_2' fill='%230D0F10' d='m42.5417,39.3333c-5.3158,0 -9.625,4.3093 -9.625,9.625c0,5.3158 4.3092,9.625 9.625,9.625c5.3157,0 9.625,-4.3092 9.625,-9.625c0,-5.3157 -4.3093,-9.625 -9.625,-9.625zm44.9167,0c-5.3158,0 -9.625,4.3093 -9.625,9.625c0,5.3158 4.3092,9.625 9.625,9.625c5.3157,0 9.625,-4.3092 9.625,-9.625c0,-5.3157 -4.3093,-9.625 -9.625,-9.625z' clip-rule='evenodd' fill-rule='evenodd'/> <path id='svg_3' fill='%230D0F10' d='m65,119.542c30.1225,0 54.542,-24.4195 54.542,-54.542c0,-30.1226 -24.4195,-54.5417 -54.542,-54.5417c-30.1226,0 -54.5417,24.4191 -54.5417,54.5417c0,30.1225 24.4191,54.542 54.5417,54.542zm0,9.625c35.438,0 64.167,-28.729 64.167,-64.167c0,-35.4383 -28.729,-64.16669 -64.167,-64.16669c-35.4383,0 -64.16669,28.72839 -64.16669,64.16669c0,35.438 28.72839,64.167 64.16669,64.167z' clip-rule='evenodd' fill-rule='evenodd'/></svg>");
    }
}

// TODO AS: These are other color versions of the icons found in the GVSG. These should eventually
// be added into the GVSG. They've been placed here for convenience until they're approved or
// replaced by the GVSG team.
.eden-icon {
    &.eden-icon-blue {
        &.eden-icon-alert-warning-filled {
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23DC3838'><path d='M11.104 3.717a1.035 1.035 0 011.792 0l8.964 15.526c.398.69-.1 1.552-.897 1.552H3.037a1.035 1.035 0 01-.897-1.552l8.964-15.525zm2.075 5.148h-2.357l.222 5.685h1.914l.22-5.685zm.081 8.185a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z' fillRule='evenOdd' clipRule='evenOdd' fill='%23DC3838'/></svg>");
        }

        &.eden-icon-edit {
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23157493'><path d='M16.255 5.649l.893-.894.354-.353a.244.244 0 0 1 .344.01l1.768 1.767a.244.244 0 0 1 .01.344L18.375 7.77l-2.12-2.121zm-6.57 10.813c-.287.286-.898.638-1.294.744l-2.402.804.83-2.376c.106-.396.458-1.007.744-1.294l7.631-7.63 2.122 2.12-7.631 7.632zM20.662 5.105l-1.768-1.767a1.754 1.754 0 0 0-2.48.005l-1.232 1.232-1.06 1.06-7.852 7.852c-.481.481-.993 1.34-1.186 1.993l-1.05 3.556c-.17.57.36 1.1.93.93l3.555-1.05c.653-.194 1.512-.705 1.994-1.187l7.85-7.85 1.061-1.061 1.232-1.232c.687-.687.69-1.797.006-2.48z' fill='%23157493'/></svg>");
        }

        &.eden-icon-info {
            background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='%23157493' xmlns='http://www.w3.org/2000/svg'><path d='M11.264 18h1.672V9.492h-1.672V18zm1.922-11.023c0-.586-.461-1.032-1.086-1.032s-1.086.446-1.086 1.032c0 .578.461 1.023 1.086 1.023s1.086-.445 1.086-1.023zM12 20.5a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17zm0 1.5C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z' fill='%23157493'/></svg>");
        }

        &.eden-icon-help {
            background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='%23157493' xmlns='http://www.w3.org/2000/svg'><path d='M8.772 9.672h1.336c.041-.557.24-.999.597-1.324.357-.326.83-.489 1.418-.489.584 0 1.049.16 1.395.477.346.318.52.719.52 1.203 0 .417-.097.766-.29 1.047-.192.281-.515.56-.968.836-.537.318-.933.664-1.188 1.039-.255.375-.38.82-.375 1.336v.719h1.367v-.508c0-.412.089-.749.266-1.012.177-.263.513-.54 1.008-.832.5-.302.897-.673 1.191-1.113.294-.44.442-.968.442-1.582 0-.808-.306-1.48-.918-2.02-.612-.539-1.41-.808-2.395-.808-1.099 0-1.93.294-2.496.882-.565.589-.868 1.305-.91 2.149zm3.234 8.469c.318 0 .577-.102.778-.305.2-.203.3-.464.3-.781 0-.323-.1-.586-.3-.79-.2-.203-.46-.304-.778-.304-.317 0-.578.101-.781.305-.203.203-.305.466-.305.789 0 .317.102.578.305.78.203.204.464.306.781.306zM12 20.5a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17zm0 1.5C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z' fill='%23157493'/></svg>");
        }

        &.eden-icon-print {
            background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='%23157493' xmlns='http://www.w3.org/2000/svg'><path d='M5.24 5.5h13.52a.75.75 0 0 0 0-1.5H5.24a.75.75 0 0 0-.74.75.75.75 0 0 0 .74.75zM20.22 6.5H3.78A1.26 1.26 0 0 0 2.5 7.75v8A1.26 1.26 0 0 0 3.76 17H5.5v2.5a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V17h1.73a1.26 1.26 0 0 0 1.27-1.25v-8a1.27 1.27 0 0 0-1.28-1.25zM17 19H7v-4h10zm3-3.5h-1.5v-2h-13v2H4V8h16z' fill='%23157493'/><path d='M6.25 11.5h4.09a.75.75 0 1 0 0-1.5H6.25a.75.75 0 1 0 0 1.5z' fill='%23157493'/></svg>");
        }

        &.eden-icon-rotate-right {
            background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='%23157493' xmlns='http://www.w3.org/2000/svg'><path d='M17.984 17.594a.803.803 0 0 1 1.137 1.137 8.852 8.852 0 0 1-12.525 0 8.852 8.852 0 0 1 0-12.525c3.338-3.338 8.678-3.465 12.151-.358L18.58 3.87a.79.79 0 0 1 .723-.867.794.794 0 0 1 .875.731l.357 4.014a.813.813 0 0 1-.731.874l-4.005.35a.829.829 0 0 1-.89-.731.828.828 0 0 1 .739-.883l2.17-.182c-2.838-2.663-7.312-2.607-10.086.167a7.245 7.245 0 0 0 0 10.251 7.245 7.245 0 0 0 10.252 0z' fill='%23157493'/></svg>")
        }

        &.eden-icon-sync {
            background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='%23157493' xmlns='http://www.w3.org/2000/svg'><path d='M22.401 8.309a.745.745 0 0 0-1.046.143l-.916 1.216a8.733 8.733 0 0 0-1.101-2.482 8.743 8.743 0 0 0-14.783.175.75.75 0 0 0 1.278.786 7.244 7.244 0 0 1 12.248-.144c.431.664.739 1.385.933 2.135L17.51 9.004a.747.747 0 0 0-1.058.14.753.753 0 0 0 .155 1.057l2.779 2.096a.748.748 0 0 0 1.084-.181l.03-.037 2.052-2.724a.75.75 0 0 0-.15-1.046zm-3.196 7.2a.75.75 0 0 0-1.031.246 7.244 7.244 0 0 1-12.248.144 7.22 7.22 0 0 1-.943-2.17l1.506 1.095a.746.746 0 0 0 1.054-.162.753.753 0 0 0-.174-1.053l-2.814-2.046a.747.747 0 0 0-1.055.16c-.01.013-.018.026-.027.04l-.029.037-2.004 2.76a.75.75 0 0 0 1.214.881l.903-1.245a8.749 8.749 0 0 0 1.112 2.52 8.743 8.743 0 0 0 14.782-.175.75.75 0 0 0-.246-1.032z' fill='%23157493'/></svg>");
        }

        &.eden-icon-search {
            background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='%23157493' xmlns='http://www.w3.org/2000/svg'><path d='M21.07 19.65l-5-5a7.5 7.5 0 1 0-1.41 1.41l5 5a1 1 0 0 0 1.41-1.41zm-6.72-5.3a6 6 0 1 1 0-8.49 6 6 0 0 1 0 8.49z' fill='%23157493'/></svg>");
        }

        &.eden-icon-clear {
            background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='%23157493' xmlns='http://www.w3.org/2000/svg'><path d='M11.4 13.06l4.596 4.597a.749.749 0 1 0 1.06-1.06l-4.595-4.597 4.596-4.596a.749.749 0 1 0-1.06-1.06l-4.597 4.595-4.596-4.596a.749.749 0 1 0-1.06 1.06l4.595 4.597-4.596 4.596a.749.749 0 1 0 1.06 1.06l4.597-4.595z' fill='%23157493'/></svg>");
        }

        &.eden-icon-add {
            background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='%23157493' xmlns='http://www.w3.org/2000/svg'><path d='M12.75 12.75h6.5a.749.749 0 1 0 0-1.5h-6.5v-6.5a.749.749 0 1 0-1.5 0v6.5h-6.5a.749.749 0 1 0 0 1.5h6.5v6.5a.749.749 0 1 0 1.5 0v-6.5z' fill='%23157493'/></svg>")
        }

        &.eden-icon-chevron-left {
            background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='%23157493' xmlns='http://www.w3.org/2000/svg'><path d='M7.777 11.462a.75.75 0 0 0 0 1.076l5.4 5.25a.75.75 0 0 0 1.046-1.076L9.365 12.01l4.858-4.722a.75.75 0 0 0-1.046-1.076l-5.4 5.25z' fill='%23157493'/></svg>")
        }
    }

    &.eden-icon-dark-blue {
        &.eden-icon-add {
            background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='%23105970' xmlns='http://www.w3.org/2000/svg'><path d='M12.75 12.75h6.5a.749.749 0 1 0 0-1.5h-6.5v-6.5a.749.749 0 1 0-1.5 0v6.5h-6.5a.749.749 0 1 0 0 1.5h6.5v6.5a.749.749 0 1 0 1.5 0v-6.5z' fill='%23105970'/></svg>")
        }
    }

    &.eden-icon-gray {
        &.eden-icon-search {
            background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='%2353575B' xmlns='http://www.w3.org/2000/svg'><path d='M21.07 19.65l-5-5a7.5 7.5 0 1 0-1.41 1.41l5 5a1 1 0 0 0 1.41-1.41zm-6.72-5.3a6 6 0 1 1 0-8.49 6 6 0 0 1 0 8.49z' fill='%2353575B'/></svg>");
        }
    }

    &.eden-icon-white {
        &.eden-icon-home {
            background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M6.5 7.5l4.968-4.968a.752.752 0 0 1 1.064 0l8.835 8.835a.75.75 0 1 1-1.06 1.06l-.807-.806v9.624a.75.75 0 0 1-.75.755H5.25a.746.746 0 0 1-.75-.757v-9.622l-.807.807a.75.75 0 1 1-1.06-1.06L4.5 9.5V5.753c0-.416.33-.753.745-.753h.51c.412 0 .745.341.745.752V7.5zM18 10.121l-6-6-6 6V20.5h12V10.121z' fill='white'/></svg>");
        }

        &.eden-icon-search {
            background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M21.07 19.65l-5-5a7.5 7.5 0 1 0-1.41 1.41l5 5a1 1 0 0 0 1.41-1.41zm-6.72-5.3a6 6 0 1 1 0-8.49 6 6 0 0 1 0 8.49z' fill='white'/></svg>");
        }

        &.eden-icon-add {
            background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M12.75 12.75h6.5a.749.749 0 1 0 0-1.5h-6.5v-6.5a.749.749 0 1 0-1.5 0v6.5h-6.5a.749.749 0 1 0 0 1.5h6.5v6.5a.749.749 0 1 0 1.5 0v-6.5z' fill='white'/></svg>");
        }
    }

    &.eden-icon-yellow {
        &.eden-icon-circle-started {
            background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='%23FAA61A' xmlns='http://www.w3.org/2000/svg'><path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM3.5 12c0-4.69 3.81-8.5 8.5-8.5 4.69 0 8.5 3.81 8.5 8.5h-17z' fill='%23FAA61A'/></svg>");
        }
    }
}
